<template>
  <div id="content" class="app-content p-0">
    <!-- BEGIN profile -->
    <div class="profile">
      <!-- BEGIN profile-header -->
      <div class="profile-header">
        <div class="profile-header-cover" />

        <div class="profile-header-content">
          <div class="profile-header-img position-relative">
            <img :src="user.avatar_url" :alt="user.full_name" />
            <div class="position-absolute top-0 right-0">
              <button class="p-2 border-0 bg-transparent" :disabled="updateIsDisabled" v-b-modal="'updateAvatar'">
                <i class="fa fa-edit" />
              </button>
              <b-modal id="updateAvatar" :title="`Update Avatar: ${user.full_name}`" v-slot="{ ok }" hide-footer>
                <validation-observer v-slot="{ invalid }">
                  <validation-provider name="Avatar" v-slot="context" rules="required">
                    <b-form-group label="Avatar">
                      <b-form-file
                        v-model="avatar"
                        :state="getValidationState(context)"
                        :disabled="updateIsDisabled"
                        v-bind="context.ariaInput"
                        accept="image/*"
                      />

                      <b-form-invalid-feedback v-bind="context.ariaMsg">
                        {{ context.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                  <hr />
                  <b-row align-h="end">
                    <b-col cols="auto">
                      <b-button variant="primary" :disabled="invalid" @click="uploadAvatar(ok)">Upload</b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-modal>
            </div>
          </div>
          <ul class="profile-header-tab nav nav-tabs nav-tabs-v2">
            <li class="nav-item">
              <router-link
                :to="{ name: 'users.profile', params: { id: user.id } }"
                :class="['nav-link', { active: $route.name === 'users.profile' }]"
              >
                <div class="nav-value">Orders</div>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'users.settings', params: { id: user.id } }"
                :class="['nav-link', { active: $route.name === 'users.settings' }]"
              >
                <div class="nav-value">Settings</div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- END profile-header -->

      <!-- BEGIN profile-container -->
      <div class="profile-container">
        <!-- BEGIN profile-sidebar -->
        <div class="profile-sidebar">
          <div class="desktop-sticky-top">
            <!-- profile info -->
            <b-row>
              <b-col v-if="user.email_verified_at" cols="auto">
                <i class="fa fa-certificate text-primary" title="verified" />
              </b-col>
              <b-col>
                <h4>{{ user.full_name }}</h4>
              </b-col>
            </b-row>

            <div class="mb-1">
              <b-row>
                <b-col cols="auto">
                  <i class="fa fa-envelope fa-fw text-muted" />
                </b-col>
                <b-col>{{ user.email }}</b-col>
              </b-row>
            </div>
            <hr />
            <div class="mb-3">
              <div class="text-muted">
                <small><strong>Address</strong></small>
              </div>
              <span>{{ user.address }}, </span>
              <strong>{{ user.city }}</strong>
            </div>
            <hr />
            <div class="mb-3">
              <b-row>
                <b-col cols="auto">
                  <i class="fa fa-map-marker-alt fa-fw text-muted" />
                </b-col>
                <b-col>
                  Last logged in: <br />
                  <template v-if="user.last_login_date">
                    <strong>{{ formatDate(user.last_login_date) }}</strong>
                  </template>
                  <b-badge v-else variant="secondary">Never</b-badge>
                </b-col>
              </b-row>
            </div>
            <hr />
            <div>
              <b-row>
                <b-col cols="auto">
                  <i class="fa fa-shopping-basket fa-fw text-muted" />
                </b-col>
                <b-col>
                  Last purchased on: <br />
                  <template v-if="user.last_purchase_date">
                    <strong>{{ formatDate(user.last_purchase_date) }}</strong>
                  </template>
                  <b-badge v-else variant="secondary">Never</b-badge>
                </b-col>
              </b-row>
            </div>
            <hr />
            <div>
              <b-row>
                <b-col cols="auto">
                  <i class="fa fa-rss fa-fw text-muted" />
                </b-col>
                <b-col>
                  <b-badge :variant="user.is_subscribed ? 'primary' : 'secondary'">
                    {{ user.is_subscribed ? 'Subscribed' : 'Unsubscribed' }}
                  </b-badge>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!-- END profile-sidebar -->

        <!-- BEGIN profile-content -->
        <div class="profile-content">
          <router-view :key="$route.fullPath" :profile="computedUser" />
        </div>
        <!-- END profile-content -->
      </div>
      <!-- END profile-container -->
    </div>
    <!-- END profile -->
  </div>
</template>

<script>
import { account, users } from '@/api'
import { mapGetters } from 'vuex'
import { formatDate, getValidationState } from '../page-helpers'

export default {
  name: 'UserProfile',
  data() {
    return {
      user: { id: 0, first_name: '', last_name: '', email: '', phone: '', address: '', zip: '', city: '' },
      avatar: null,
    }
  },
  computed: {
    ...mapGetters({ loggedInUser: 'auth/user' }),
    computedUser() {
      return this.user
    },
    profileIsLoggedIn() {
      return this.user.id == this.loggedInUser.id
    },
    userProfileIsAdmin() {
      const roles = ['Super Admin', 'Dashboard User']

      return this.user.roles?.some(role => roles.includes(role))
    },
    updateIsDisabled() {
      return this.userProfileIsAdmin && !this.profileIsLoggedIn
    },
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    formatDate,
    getValidationState,
    async loadUser() {
      const user = await users.find(this.$route.params.id)

      this.user = user.data
    },
    async uploadAvatar(done) {
      this.loggedInUser.id === this.user.id ? await account.update({ avatar: this.avatar }) : await users.update(this.user.id, { avatar: this.avatar })
      done()
    },
  },
}
</script>
